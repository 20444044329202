//inspiration from https://www.youtube.com/watch?v=Jgdx_qykoPw

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {navigate} from "gatsby"
import {Card, CardActions, CardContent, Container, TextField} from '@material-ui/core';
import {useUserContext} from '../context/UserContext';
import Header from "./common/Header";
import BackendError from "./common/BackendError";
import ButtonStyled from "./common/ButtonStyled";

import {useStyles} from "../styles/LoginStyles";
import { useTranslation } from 'react-i18next';

interface LoginData {
    username: string,
    password: string
}

export default function LoginForm() {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const { setLoggedInClient, logIn, loggedInOnBackend, logInExtended, refreshToken } = useUserContext();

    useEffect(() => {
        loggedInOnBackend().then((res)=>{
            if(res){
                setLoggedInClient(true);
                if(location.search != ""){
                    const params = new URLSearchParams(location.search);
                    setLoggedInClient(true);
                    navigate(params.get('redirect'));
                }
                navigate('/products')
            }else{
                if(refreshToken!=null){
                    logInExtended().then((res)=>{
                        if(res){
                            setLoggedInClient(true);
                            if(location.search != ""){
                                const params = new URLSearchParams(location.search);
                                setLoggedInClient(true);
                                navigate(params.get('redirect'));
                            }else navigate('/products')
                        }
                    })
                }
            }
        })
    },[]);


    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>(); //Possible to use useForm<FormData>({mode: "onChange"});
    const [backendError, setBackendError] = useState(null);

    const onSubmit = handleSubmit(({username, password}) => {
        logIn(username,password).then((res)=>{
            let redirect = res.mfa ? '/mfa/verify' : '/products';
            if (location.search != "") {
                const params = new URLSearchParams(location.search);
                setLoggedInClient(true);
                redirect = res.mfa ? '/mfa/verify?redirect=' + params.get('redirect')
                    : params.get('redirect');
            }
            navigate(redirect);
        }).catch((error)=>{
            if(error.response!=undefined) {
                setBackendError(i18n.language=='cs' ? error.response.data.czechMessage : error.response.data.message);
            }else setBackendError(error.message);
        })
    });

    return (
        <Container className={classes.loginContainer} >
            <form onSubmit={onSubmit} noValidate autoComplete="off">
                <Card>
                    <Header title={t("LOGIN")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                    {backendError!=null ? <BackendError error={backendError}/> : ""}
                    <CardContent>
                        <TextField
                            fullWidth
                            id="username"
                            type="text"
                            name="username"
                            size="small"
                            label={t("USERNAME")}
                            placeholder={t("USERNAME")}
                            margin="normal"
                            InputLabelProps={{className:(errors.username ? classes.formErrors : null)}}
                            {...register("username",{ required: true })}
                        />
                        {errors.username && (<div className={classes.formErrors}>{t("FILLUSERNAME")}</div>)}
                        <TextField
                            fullWidth
                            label={t("PASSWORD")}
                            name="password"
                            size="small"
                            type="password"
                            id="password"
                            placeholder={t("PASSWORD")}
                            margin="normal"
                            InputLabelProps={{className:(errors.password ? classes.formErrors : null)}}
                            {...register('password', { required: true })}
                        />
                        {errors.password && (<div className={classes.formErrors}>{t("FILLPASSWORD")}</div>)}
                    </CardContent>
                    <CardActions>
                        <ButtonStyled style={{margin: 'auto'}} type="submit" text={t("SIGNIN")}/>
                    </CardActions>
                </Card>
            </form>
        </Container>
    );
}
