//inspiration from https://www.youtube.com/watch?v=Jgdx_qykoPw

import React from 'react';
import LanguageSwitcher from '../components/LanguageSwitcher';
import LoginForm from '../components/LoginForm';

import {useStyles } from "../styles/LoginStyles"
import { useTranslation } from 'react-i18next';
import logo from "/static/images/conformityDocOpt1.png";
import cmilogoEN from "/static/images/cmi-en.png";
import cmilogoCS from "/static/images/cmi-cz.png";
import { useUserContext } from '../context/UserContext';

export default function Login() {
    const classes = useStyles();
    const { activeCompany } = useUserContext();
    const { i18n } = useTranslation();
    return (
        <div>
            <LanguageSwitcher/>
            <div className={classes.loginRoot}>
            <img src={activeCompany==2 ? i18n.language=="cs" ? cmilogoCS : cmilogoEN : logo} className={classes.logoLoginPage} alt="Logo"/>
            <LoginForm/>
            </div>
        </div>

    );
}
